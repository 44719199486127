import React, {useState} from 'react'
import TextTyper from 'text-type-animation-effect-react'

import './v3.css'

import {title} from './data/content.js'; 


import ContactForm from './components/Form.js' 

import CardWrapper from './components/ui/card-wrapper.js'


export default function V3() {


	const [loadSequens, setLoadSequense]=useState(1)




	return (
		<>
		<header className='gridHeader'>
		<div>
		<h1>
		<TextTyper text={title.toUpperCase()} interval={50} Markup={"code"} />
		</h1>
		</div>

		<div>
		<p>Web Design / Websites / Web applications / iOS Apps / Android Apps / UX Design / UI Design / Telegram Bots / Web Bots / Mobile Games / Desktop Games / Browser Games / Gamified Apps / <a href='https://barcelonacodeschool.com/corporate-training/' target='_blank' rel="noreferrer" >Custom Training</a></p>
		</div>

		<div>
		<a href='mailto:office@barcelonacodeschool.com' className='grid__child__spanTillRightEnd grid__child__vert_center'>
		<button>Email us</button>
		</a>
		</div>
		</header>

		<main>
		{/*<h2>What we do</h2>

		<article>
		<h3>User Experience Design</h3>
		<p>Before starting the development we can conduct the full process of User Experience (UX) and User Interface (UI) Design.</p>
		<p>From the problem you are trying to solve we can validate it, conduct the user research to understand that your idea will meet the users' needs, design the structure and architecture of your product, develop and test the best visual style and user-test the prototype.</p>
		<p>In this way you will know that what will be created is the best possible solution.</p>
		</article>

		<article>
		<h3>Full-Stack Engineering</h3>
		<p>Building you product for web and/or mobile platforms iOS and Android.</p>
		<p>With all the latest and modern technologies you can think of to make sure that your product is the fastest, robust and bulletproof.</p>
		<p>We have expertise and capacity to take on a project of any complexity.</p>
		</article>

		<article>
		<h3>Digital Solutions for the Future</h3>
		<p>Our software developing house is built on top of Barcelona Code School where we build software and train designers and developers since 2015.</p>
		<p>All the experience and expertise we have plus infinite pool of available developers and designers allow us to assign a team for your project from the start and deliver in the shortest time with the best quality possible.</p>
		</article>


		<h2>How we work</h2>

		<article>
		<h3><span className='for2col'>1: </span>Meeting you</h3>
		<p>First you will have a thorough conversation with your Project Manager and the User Experience Designer. We will listen to you and ask a lot of questions to understand your product.</p> 
		<p> We will identify all the features you need, understand your target audience, the problem you are trying to solve and find out the visual references for the esthetics of your product.</p>
		</article>

		<article>
		<h3><span className='for2col'>2: </span>UX and UI Design</h3>
		<p>We will start working on the architecture and structure of your project. Conducting full UX research with discovery, definition, infomation architecture, sketching, testing, wireframing, prototyping and usability testing.</p>
		<p>We will test several styleguides and pick the one which was perceived as the best.</p>
		</article>


		<article>
		<h3><span className='for2col'>3: </span>Development phase</h3>
		<p>Once the architecture of your project and the visual styles are ready and confirmed with you our software engineers will start working on it ro bring it to live.</p>
		<p>With the most suitable and modern technologies we will see through the continious integration and delivery in phases starting from the core functionality so you can begin testing it and rolling out the full version in the shortest time possible.</p>
		<p>We can take care of deployment and publishing if needed completing the cycle to the moment of your project being live on web or AppStore/Google Play.</p>
		</article>

		<h2>Some products we've created</h2>*/}






		<div className='gridShowcase'>

		<CardWrapper 
		url={'https://lechat.app/'}
		title={'Le Chat'}
		desc={'Website live chat with a mobile app'}
		imgUrl={"images/lechat.jpg"}
		imgAlt={"Le Chat - website live chat system"}
		/>

		<CardWrapper 
		url={'https://toolboxai.app'}
		title={'toolboxAI.app'}
		desc={'Set of AI tools on demand'}
		imgUrl={"images/toolboxAI.jpg"}
		imgAlt={"toolboxAI.app - set of AI tools on demand"}
		/>

{/*		<CardWrapper 
		url={'http://bsides.barcelona'}
		title={'BSides.barcelona'}
		desc={"CyberSecurity forum's website"}
		imgUrl={"images/bsides-barcelona.jpg"}
		imgAlt={"BSides.barcelona – CyberSecurity forum's website"}
		/>*/}

		<CardWrapper 
		url={'https://goodbadplace.com'}
		title={'Good Bad Place'}
		desc={"Rate your landlord"}
		imgUrl={"images/goodbadplace.jpg"}
		imgAlt={"Good Bad Place - landlords and rentals reviews platform"}
		/>


		<CardWrapper 
		url={'https://barcelonacodeschool.com/'}
		title={'Barcelona Code School'}
		desc={"School's website"}
		imgUrl={"images/bcs.jpg"}
		imgAlt={"Barcelona Code School"}
		/>


		<CardWrapper 
		url={'http://bxlcybersummit.surge.sh/index.html'}
		title={'Brussels CyberSecurity Summit'}
		desc={"Participants landing page"}
		imgUrl={"images/ccb.jpg"}
		imgAlt={"Brussels CyberSecurity Summit"}
		/>


		

		<CardWrapper 
		url={"https://apps.apple.com/us/app/weather-os/id6443399556"}
		title={"Weather OS"}
		desc={"iOS weather app"}
		imgUrl={"images/weatherOS.jpg"}
		imgAlt={"Weather OS - a terminal themed weather app"}
		/>

		<CardWrapper 
		url={"https://apps.apple.com/us/app/picky-eater-go/id6451353816?platform=iphone"}
		title={"Picky Eater Go!"}
		desc={"iOS/Android arcade game"}
		imgUrl={"images/pickyEaterGo.jpg"}
		imgAlt={"Picky Eater Go! - iOS arcade game"}
		/>	


		<CardWrapper 
		url={"https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298"}
		title={"Guess a Word or Die"}
		desc={"iOS/Android word game"}
		imgUrl={"images/guessaword.jpg"}
		imgAlt={"Guess a Word or Die - iOS word game"}
		/>	




		<CardWrapper 
		url={"https://apps.apple.com/us/app/i-have-i-need/id1621110781"}
		title={"I Have, I Need"}
		desc={"iOS/Android Inventory and recurring shopping list"}
		imgUrl={"images/ihaveineed.jpg"}
		imgAlt={"I Have, I Need - Inventory and recurring shopping list"}
		/>


		<CardWrapper 
		url={"https://apps.apple.com/us/app/anime-calculator/id1567378874?platform=iphone"}
		title={"Anime Calculator"}
		desc={"Anime themed calculator app for iOS and Android"}
		imgUrl={"images/animecalc.jpg"}
		imgAlt={"Anime themed calculator app for iOS and Android"}
		/>



		</div>

		</main>



		<ContactForm/>



		<footer className='centered'>
		<p><a href='https://undefeatedsoftware.dev' >Undefeated Software</a> / <a href='https://barcelonacodeschool.com/' target='_blank'>Barcelona Code School</a> © {(new Date()).getFullYear()}</p>
		<address>c/ Paris 157, 08036, Barcelona, Spain</address>
		</footer>
		</>
		)
}